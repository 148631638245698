import { createContext, useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import Swal from 'sweetalert2';
import { userActive } from '../assets/js/functions';

export const SidebarContext = createContext({});

export const SidebarProvider = ({ children }) => {
	const [isSidebarOpen, setSidebarOpen] = useState(false);

	useEffect(() => {
		validatePassword();
	}, []);

	const validatePassword = async () => {
		const data = await userActive();
		if (window.location.pathname !== '/admin/configuracion') {
			if (data.cambiarContraseña) {
				Swal.fire({
					title: '¡Espera!',
					html: `Tienes una contraseña temporal por favor cambiala por una mas segura`,
					icon: 'warning',
					confirmButtonText: 'Cambiar',
					confirmButtonColor: '#c1212a',
				}).then((result) => {
					if (result.isConfirmed) {
						window.location.replace('/admin/configuracion');
					}
				});
			}
		}
	};

	const openSidebar = () => {
		setSidebarOpen(true);
	};

	const closeSidebar = () => {
		setSidebarOpen(false);
	};

	return (
		<SidebarContext.Provider
			value={{
				isSidebarOpen,
				openSidebar,
				closeSidebar,
			}}
		>
			{children}
		</SidebarContext.Provider>
	);
};

SidebarProvider.propTypes = {
	children: PropTypes.node,
};
