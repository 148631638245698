import ReporteAdministradores from './Administradores';
import ReporteConsultores from './Consultores';

export default function Consultores() {
	return (
		<>
			{sessionStorage.getItem('8b6d1c1e') === '0242ac120002' && <ReporteAdministradores />}
			{sessionStorage.getItem('8b6d1c1e') === '76c66ae87579' && <ReporteAdministradores />}
			{sessionStorage.getItem('8b6d1c1e') === '051c0d485398' && <ReporteConsultores />}
		</>
	);
}
