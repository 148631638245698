import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdOutlineGridView, MdOutlineLogout, MdOutlinePeople, MdOutlineSettings } from 'react-icons/md';
import { FaChartSimple } from 'react-icons/fa6';
import { AiOutlineSolution, AiOutlineBarChart, AiFillShopping, AiOutlineForm, AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai';
import { stringToArray } from '../../../assets/js/functions';
import { SatisfaccionOperaciones } from '../../../assets/js/reportes';

export default function SuperAdministrador() {
	const [open1, setOpen1] = useState(false);
	const [open2, setOpen2] = useState(false);
	const [open3, setOpen3] = useState(false);
	const navigate = useNavigate();

	const getPathname = () => {
		const pathname = window.location.pathname;
		const array = stringToArray(pathname, '/');
		return array[2];
	};
	const abner = getPathname();

	const logout = () => {
		sessionStorage.clear();
		navigate('/');
	};

	const goUrl = (url) => {
		setOpen1(false);
		setOpen2(false);
		navigate(url);
	};

	return (
		<div className='sidebar-body'>
			<div className='sidebar-menu'>
				<ul className='menu-list'>
					<li className='menu-item'>
						<div onClick={() => goUrl('/admin/dashboard')} className={`${abner === 'dashboard' && 'active'} menu-link`}>
							<span className='menu-link-icon'>
								<MdOutlineGridView size={18} />
							</span>
							<span className='menu-link-text'>Dashboard</span>
						</div>
					</li>
					<li className='menu-item'>
						<div onClick={() => goUrl('/admin/usuarios')} className={`${abner === 'usuarios' ? 'active' : ''} menu-link`}>
							<span className='menu-link-icon'>
								<MdOutlinePeople size={20} />
							</span>
							<span className='menu-link-text'>Usuarios</span>
						</div>
					</li>
					<li className='menu-item'>
						<div onClick={() => goUrl('/admin/consultores')} className={`${abner === 'consultores' && 'active'} menu-link `}>
							<span className='menu-link-icon'>
								<AiOutlineSolution size={20} />
							</span>
							<span className='menu-link-text'>Consultores</span>
						</div>
					</li>
					<li className='menu-item'>
						<div
							onClick={() => {
								setOpen1(!open1);
								setOpen2(open1 && false);
								setOpen3(open1 && false);
							}}
							className={` menu-link `}
						>
							<span className='menu-link-icon'>
								<AiOutlineBarChart size={20} />
							</span>
							<span className='menu-link-text'>
								Comercial <span className='icon-menu'>{open1 ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}</span>
							</span>
						</div>
					</li>
					{open1 && (
						<>
							<li className='menu-item'>
								<div onClick={() => navigate('/admin/comercial/reporte-satisfaccion-comercial')} className={`${window.location.pathname === '/admin/comercial/reporte-satisfaccion-comercial' && 'active'} menu-link `}>
									<span className='menu-link-text' style={{ marginLeft: '30px' }}>
										Satisf. comercial
									</span>
								</div>
							</li>

							<li className='menu-item'>
								<div onClick={() => navigate('/admin/comercial/consultor')} className={`${window.location.pathname === '/admin/comercial/consultor' && 'active'} menu-link `}>
									<span className='menu-link-text' style={{ marginLeft: '30px' }}>
										Consultor
									</span>
								</div>
							</li>
						</>
					)}
					<li className='menu-item'>
						<div
							onClick={() => {
								setOpen2(!open2);
								setOpen1(open2 && false);
								setOpen3(open2 && false);
							}}
							className={` menu-link `}
						>
							<span className='menu-link-icon'>
								<AiFillShopping size={20} />
							</span>
							<span className='menu-link-text'>
								Operaciones
								<span className='icon-menu'>{open2 ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}</span>
							</span>
						</div>
					</li>
					{open2 && (
						<>
							<li className='menu-item'>
								<div onClick={() => navigate('/admin/comercial/reporte-satisfaccion-operativa')} className={`${window.location.pathname === '/admin/comercial/reporte-satisfaccion-operativa' && 'active'} menu-link `}>
									<span className='menu-link-text' style={{ marginLeft: '30px' }}>
										Satisf. operativa
									</span>
								</div>
							</li>
						</>
					)}
					<li className='menu-item'>
						<div
							onClick={() => {
								setOpen3(!open3);
								setOpen1(open3 && false);
								setOpen2(open3 && false);
							}}
							className={` menu-link `}
						>
							<span className='menu-link-icon'>
								<AiOutlineForm size={20} />
							</span>
							<span className='menu-link-text'>
								Encuestas
								<span className='icon-menu'>{open3 ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}</span>
							</span>
						</div>
					</li>
					{open3 && (
						<>
							<li className='menu-item'>
								<div onClick={() => goUrl('/admin/encuestas/encuesta-de-satisfaccion-operativa/lista')} className={`${window.location.pathname === '/admin/encuestas/encuesta-de-satisfaccion-operativa/lista' && 'active'} menu-link `}>
									<span className='menu-link-text' style={{ marginLeft: '30px' }}>
										Satisfacción operativa
									</span>
								</div>
							</li>
							<li className='menu-item'>
								<div onClick={() => navigate('/admin/encuestas/encuesta-de-satisfaccion-comercial/lista')} className={`${window.location.pathname === '/admin/encuestas/encuesta-de-satisfaccion-comercial/lista' && 'active'} menu-link `}>
									<span className='menu-link-text' style={{ marginLeft: '30px' }}>
										Satisfacción comercial
									</span>
								</div>
							</li>
						</>
					)}
					{sessionStorage.getItem('3c393f98') === 'lbqsp5hw' && (
						<li className='menu-item'>
							<div className={` menu-link `} onClick={SatisfaccionOperaciones}>
								<span className='menu-link-icon'>
									<FaChartSimple size={20} />
								</span>
								<span className='menu-link-text'>Sat. oper. anteriores</span>
							</div>
						</li>
					)}
				</ul>
			</div>

			<div className='sidebar-menu sidebar-menu2'>
				<ul className='menu-list'>
					<li className='menu-item'>
						<div onClick={() => navigate('/admin/configuracion')} className={`${abner === 'configuracion' && 'active'} menu-link`}>
							<span className='menu-link-icon'>
								<MdOutlineSettings size={20} />
							</span>
							<span className='menu-link-text'>Configuración</span>
						</div>
					</li>
					<li className='menu-item'>
						<div onClick={logout} className='menu-link'>
							<span className='menu-link-icon'>
								<MdOutlineLogout size={20} />
							</span>
							<span className='menu-link-text'>Logout</span>
						</div>
					</li>
				</ul>
			</div>
		</div>
	);
}
