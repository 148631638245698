import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdOutlineGridView, MdOutlineLogout, MdOutlinePeople, MdOutlineSettings } from 'react-icons/md';
import { AiOutlineSolution, AiOutlineBarChart, AiFillShopping } from 'react-icons/ai';
import { stringToArray } from '../../../assets/js/functions';

export default function Administrador() {
	const [open1, setOpen1] = useState(false);
	const [open2, setOpen2] = useState(false);
	const navigate = useNavigate();

	const getPathname = () => {
		const pathname = window.location.pathname;
		const array = stringToArray(pathname, '/');
		return array[2];
	};
	const abner = getPathname();

	const logout = () => {
		sessionStorage.clear();
		navigate('/');
	};

	const goUrl = (url) => {
		setOpen1(false);
		setOpen2(false);
		navigate(url);
	};

	return (
		<div className='sidebar-body'>
			<div className='sidebar-menu'>
				<ul className='menu-list'>
					<li className='menu-item'>
						<div onClick={() => goUrl('/admin/dashboard')} className={`${abner === 'dashboard' && 'active'} menu-link`}>
							<span className='menu-link-icon'>
								<MdOutlineGridView size={18} />
							</span>
							<span className='menu-link-text'>Dashboard</span>
						</div>
					</li>
					<li className='menu-item'>
						<div
							onClick={() => {
								setOpen1(!open1);
								setOpen2(open1 && false);
							}}
							className={`${abner === 'comercial' && 'active'} menu-link `}
						>
							<span className='menu-link-icon'>
								<AiOutlineBarChart size={20} />
							</span>
							<span className='menu-link-text'>Comercial</span>
						</div>
					</li>
					{open1 && (
						<>
							<li className='menu-item'>
								<div onClick={() => navigate('/admin/comercial/negocios-ganados')} className={`${window.location.pathname === '/admin/comercial/negocios-ganados' && 'active-two'} menu-link `}>
									<span className='menu-link-text' style={{ marginLeft: '30px' }}>
										Negocios Ganados
									</span>
								</div>
							</li>
							<li className='menu-item'>
								<div onClick={() => navigate('/admin/comercial/negocios-perdidos')} className={`${window.location.pathname === '/admin/comercial/negocios-perdidos' && 'active-two'} menu-link `}>
									<span className='menu-link-text' style={{ marginLeft: '30px' }}>
										Negocios Perdidos
									</span>
								</div>
							</li>
							<li className='menu-item'>
								<div onClick={() => navigate('/admin/comercial/consultor')} className={`${window.location.pathname === '/admin/comercial/consultor' && 'active-two'} menu-link `}>
									<span className='menu-link-text' style={{ marginLeft: '30px' }}>
										Consultor
									</span>
								</div>
							</li>
						</>
					)}
					<li className='menu-item'>
						<div
							onClick={() => {
								setOpen2(!open2);
								setOpen1(open2 && false);
							}}
							className={`${abner === 'operaciones' && 'active'} menu-link `}
						>
							<span className='menu-link-icon'>
								<AiFillShopping size={20} />
							</span>
							<span className='menu-link-text'>Operaciones</span>
						</div>
					</li>
					{open2 && (
						<>
							<li className='menu-item'>
								<div onClick={() => navigate('/admin/operaciones/satisfaccion-ode')} className={`${window.location.pathname === '/admin/operaciones/satisfaccion-ode' && 'active-two'} menu-link `}>
									<span className='menu-link-text' style={{ marginLeft: '30px' }}>
										ODE
									</span>
								</div>
							</li>
							<li className='menu-item'>
								<div onClick={() => navigate('/admin/operaciones/encuesta-de-satisfaccion-operativa')} className={`${window.location.pathname === '/admin/operaciones/encuesta-de-satisfaccion-operativa' && 'active-two'} menu-link `}>
									<span className='menu-link-text' style={{ marginLeft: '30px' }}>
										Operaciones
									</span>
								</div>
							</li>
						</>
					)}
				</ul>
			</div>

			<div className='sidebar-menu sidebar-menu2'>
				<ul className='menu-list'>
					{/* <li className='menu-item'>
							<div onClick={() => navigate('/admin/configuracion')} className={`${abner === 'configuracion' && 'active'} menu-link`}>
								<span className='menu-link-icon'>
									<MdOutlineSettings size={20} />
								</span>
								<span className='menu-link-text'>Configuración</span>
							</div>
						</li> */}
					<li className='menu-item'>
						<div onClick={logout} className='menu-link'>
							<span className='menu-link-icon'>
								<MdOutlineLogout size={20} />
							</span>
							<span className='menu-link-text'>Logout</span>
						</div>
					</li>
				</ul>
			</div>
		</div>
	);
}
