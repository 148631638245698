import { useState, useEffect, createRef } from 'react';
import { db } from '../../../../config/firebase';
import { ref, onChildChanged } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { useScreenshot, createFileName } from 'use-react-screenshot';
import Grid from '@mui/system/Unstable_Grid';
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Cell, ResponsiveContainer, LabelList, ComposedChart } from 'recharts';
import groupBy from 'lodash.groupby';
import SectionTitle from '../../../../components/SeccionTitle';
import { getCollections, getCollectionsComplete } from '../../../../assets/js/functions';
import '../styles.scss';
import { StarPurple500 } from '@mui/icons-material';

export default function ConsultoresAdministradores() {
	const navigate = useNavigate();
	const [total, setTotal] = useState(0);
	// satisfaccion general
	const [satisfaccionGeneral, setSatisfaccionGeneral] = useState([]);
	const reference = createRef(null);
	const [image, takeScreenshot] = useScreenshot();

	const download = (image, { name = 'satisfaccion-consultores', extension = 'png' } = {}) => {
		const a = document.createElement('a');
		a.href = image;
		a.download = createFileName(extension, name);
		a.click();
	};

	const getImage = () => {
		takeScreenshot(reference.current).then(download);
	};

	const COLORS = ['#5499C7 ', '#EC7063', '#C39BD3', '#48C9B0', '#CD6155', '#F1948A', '#EB984E', '#FF8042', '#00C49F', '#F9E79F'];

	const get = async () => {
		const resultados = await getCollections('encuestaDeSatisfaccionComercial');
		const orderData = groupBy(resultados, 'idUsuario');
		satisfaccion(orderData);
	};

	const satisfaccion = async (array) => {
		const usuarios = await getCollectionsComplete('usuarios');
		const arrayResultados = [];
		Object.values(array).map((val) => {
			let jsonResult = {};
			let sumEncuestas = 0;
			let suma = 0;
			let sumaTotal = 0;
			let promedio = 0;
			Object.values(val).map((values) => {
				const data = usuarios[values.idUsuario];
				sumaTotal = Object.keys(val).length;

				if (values.status === 'Terminado') {
					Object.values(values.respuestas).map((res) => {
						sumEncuestas = sumEncuestas + Object.keys(values.respuestas).length;
						suma =
							suma +
							parseInt(res.preguntaCinco) +
							parseInt(res.preguntaSeis) +
							parseInt(res.preguntaSiete) +
							parseInt(res.preguntaOcho) +
							parseInt(res.preguntaNueve) +
							parseInt(res.preguntaDiez) +
							parseInt(res.preguntaOnce) +
							parseInt(res.preguntaDoce) +
							parseInt(res.preguntaTrece) +
							parseInt(res.preguntaCatorce);
					});
					promedio = suma / sumEncuestas / 10;
					jsonResult = {
						name: data.nombre,
						promedio: parseFloat(promedio.toFixed(2)),
						encuestas: sumEncuestas,
						id: data.id,
						contestadas: sumaTotal,
					};
				} else {
					jsonResult = {
						name: data.nombre,
						promedio: parseFloat(promedio.toFixed(2)),
						encuestas: sumEncuestas,
						id: data.id,
						contestadas: sumaTotal,
					};
				}
			});
			arrayResultados.push(jsonResult);
			setTotal(sumaTotal);
		});
		setSatisfaccionGeneral(arrayResultados);
	};

	useEffect(() => {
		get();
		const dbRef = ref(db, 'encuestaDeSatisfaccionComercial/');
		onChildChanged(dbRef, (data) => {
			get();
		});
	}, []);

	const CustomTooltip = ({ active, payload, label }) => {
		if (active && payload && payload.length) {
			return (
				<div className='custom-tooltip'>
					<p className='label'>{`${label}`}</p>
					<p className='label'>{`Promedio : ${payload[0].payload.promedio}`}</p>
					<p className='label'>{`Encuestas : ${payload[0].payload.contestadas}`}</p>
					<p className='label'>{`Contestadas : ${payload[0].payload.encuestas}`}</p>
				</div>
			);
		}
		return null;
	};

	return (
		<>
			<div className='content-area'>
				<SectionTitle title='Satisfacción por consultor' />
				<section id='reporteConsultor'>
					<Grid direction='row' alignItems='center' justifyContent='center' spacing={2}>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<div ref={reference} style={{ padding: '15px' }}>
								<ResponsiveContainer width='100%' height={900}>
									<ComposedChart
										layout='vertical'
										data={satisfaccionGeneral}
										margin={{
											left: 20,
										}}
									>
										<CartesianGrid stroke='#f5f5f5' />
										<XAxis type='number' domain={[0, 5]} ticks={[1, 2, 3, 4, 5]} />
										<YAxis dataKey='name' type='category' />
										<Tooltip content={<CustomTooltip />} />
										<Bar dataKey='promedio' position='centerTop'>
											{satisfaccionGeneral.map((entry, index) => (
												<>
													<Cell
														style={{ cursor: sessionStorage.getItem('3c393f98') === entry.id ? 'cursor' : '' }}
														onClick={(e) => (sessionStorage.getItem('3c393f98') === entry.id ? navigate(`/admin/comercial/consultor/${entry.id}`) : null)}
														key={`cell-${index}`}
														id={`cell-${index}`}
														fill={COLORS[index % COLORS.length]}
													/>
												</>
											))}
											<LabelList fill='#000' dataKey='promedio' position='centerTop' />
										</Bar>
									</ComposedChart>
								</ResponsiveContainer>
							</div>
						</Grid>
					</Grid>
					<Grid direction='row' alignItems='center' justifyContent='center' spacing={2}>
						<Grid item lg={2} className='center'>
							<div className='btn' onClick={getImage}>
								Descargar gráfica
							</div>
						</Grid>
					</Grid>
				</section>
			</div>
		</>
	);
}
