import { useContext, useEffect, useRef } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import { LIGHT_THEME } from '../../constants/themeConstants';
import LogoColor from '../../assets/images/Spira-Color.png';
import LogoBlanco from '../../assets/images/Spira-blanco.png';
import { MdOutlineClose } from 'react-icons/md';
import './Sidebar.scss';
import { SidebarContext } from '../../context/SidebarContext';
import SuperAdministrador from './SuperAdministrador';
import Administrador from './Administrador';
import Consultor from './Consultor';

const Sidebar = () => {
	const { theme } = useContext(ThemeContext);
	const { isSidebarOpen, closeSidebar } = useContext(SidebarContext);
	const navbarRef = useRef(null);

	// closing the navbar when clicked outside the sidebar area
	const handleClickOutside = (event) => {
		if (navbarRef.current && !navbarRef.current.contains(event.target) && event.target.className !== 'sidebar-oepn-btn') {
			closeSidebar();
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<nav className={`sidebar ${isSidebarOpen ? 'sidebar-show' : ''}`} ref={navbarRef}>
			<div className='sidebar-top'>
				<div className='sidebar-brand'>
					<img style={{ width: '80%' }} src={theme === LIGHT_THEME ? LogoColor : LogoBlanco} alt='' />
				</div>
				<button className='sidebar-close-btn' onClick={closeSidebar}>
					<MdOutlineClose size={24} />
				</button>
			</div>
			{sessionStorage.getItem('8b6d1c1e') === '0242ac120002' && <SuperAdministrador />}
			{sessionStorage.getItem('8b6d1c1e') === '76c66ae87579' && <Administrador />}
			{sessionStorage.getItem('8b6d1c1e') === '051c0d485398' && <Consultor />}
		</nav>
	);
};

export default Sidebar;
