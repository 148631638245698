import { useContext, useEffect } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import { DARK_THEME, LIGHT_THEME } from '../../constants/themeConstants';
import MoonIcon from '../../assets/icons/moon.svg';
import SunIcon from '../../assets/icons/sun.svg';

export default function Navigation() {
	const { theme, toggleTheme } = useContext(ThemeContext);

	useEffect(() => {
		if (theme === DARK_THEME) {
			document.body.classList.add('dark-mode');
		} else {
			document.body.classList.remove('dark-mode');
		}
	}, [theme]);

	return (
		<>
			<button type='button' className='theme-toggle-btn' onClick={toggleTheme}>
				<img className='theme-icon' src={theme === LIGHT_THEME ? SunIcon : MoonIcon} />
			</button>
		</>
	);
}
