import React, { useState, useEffect, createRef } from 'react';
import { db } from '../../../config/firebase';
import { ref, onChildChanged } from 'firebase/database';
import { useScreenshot, createFileName } from 'use-react-screenshot';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Cell, ResponsiveContainer, LabelList } from 'recharts';
import { useParams, useNavigate } from 'react-router-dom';
import Grid from '@mui/system/Unstable_Grid';
import groupBy from 'lodash.groupby';
import Nomenglatura from './Nomenglatura';
import SectionTitle from '../../../components/SeccionTitle';
import { getCollections, getCollectionsComplete } from '../../../assets/js/functions';
import { reporteConsultoresExcel } from '../../../assets/js/reportes';
import './styles.scss';

export default function ReporteDetalladoConsultor() {
	let { id } = useParams();
	const navigate = useNavigate();
	const [registros, setRegistros] = useState(0);
	const [total, setTotal] = useState(0);
	const [nombre, setNombre] = useState('');

	// satisfaccion general
	const [satisfaccionGeneral, setSatisfaccionGeneral] = useState([]);
	const reference = createRef(null);
	const [image, takeScreenshot] = useScreenshot();

	const download = (image, { name = 'satisfaccion-consultores', extension = 'png' } = {}) => {
		const a = document.createElement('a');
		a.href = image;
		a.download = createFileName(extension, name);
		a.click();
	};

	const getImage = () => {
		takeScreenshot(reference.current).then(download);
	};

	const COLORS = ['#5499C7 ', '#EC7063', '#C39BD3', '#48C9B0', '#CD6155', '#F1948A', '#EB984E', '#FF8042', '#00C49F', '#F9E79F'];

	const get = async () => {
		let arrayResp = [];
		let suma = 0;
		const resultados = await getCollections('encuestaDeSatisfaccionComercial');
		Object.values(resultados).map((val) => {
			if (val.idUsuario === id) {
				suma++;
				if (val.respuestas) {
					Object.values(val.respuestas).map((res) => {
						arrayResp.push(res);
					});
				}
			}
		});
		satisfaccion(arrayResp);
		setTotal(suma);
	};

	const satisfaccion = async (array) => {
		const arrayResultados = [];
		let sumaP5 = 0;
		let sumaP6 = 0;
		let sumaP7 = 0;
		let sumaP8 = 0;
		let sumaP9 = 0;
		let sumaP10 = 0;
		let sumaP11 = 0;
		let sumaP12 = 0;
		let sumaP13 = 0;
		let sumaP14 = 0;
		let promedio5 = 0;
		let promedio6 = 0;
		let promedio7 = 0;
		let promedio8 = 0;
		let promedio9 = 0;
		let promedio10 = 0;
		let promedio11 = 0;
		let promedio12 = 0;
		let promedio13 = 0;
		let promedio14 = 0;
		Object.values(array).map((res) => {
			sumaP5 += parseInt(res.preguntaCinco);
			sumaP6 += parseInt(res.preguntaSeis);
			sumaP7 += parseInt(res.preguntaSiete);
			sumaP8 += parseInt(res.preguntaOcho);
			sumaP9 += parseInt(res.preguntaNueve);
			sumaP10 += parseInt(res.preguntaDiez);
			sumaP11 += parseInt(res.preguntaOnce);
			sumaP12 += parseInt(res.preguntaDoce);
			sumaP13 += parseInt(res.preguntaTrece);
			sumaP14 += parseInt(res.preguntaCatorce);
			promedio5 = sumaP5 / array.length;
			promedio6 = sumaP6 / array.length;
			promedio7 = sumaP7 / array.length;
			promedio8 = sumaP8 / array.length;
			promedio9 = sumaP9 / array.length;
			promedio10 = sumaP10 / array.length;
			promedio11 = sumaP11 / array.length;
			promedio12 = sumaP12 / array.length;
			promedio13 = sumaP13 / array.length;
			promedio14 = sumaP14 / array.length;
		});
		arrayResultados.push({
			name: 'P5',
			promedio: parseFloat(promedio5.toFixed(2)),
		});
		arrayResultados.push({
			name: 'P6',
			promedio: parseFloat(promedio6.toFixed(2)),
		});
		arrayResultados.push({
			name: 'P7',
			promedio: parseFloat(promedio7.toFixed(2)),
		});
		arrayResultados.push({
			name: 'P8',
			promedio: parseFloat(promedio8.toFixed(2)),
		});
		arrayResultados.push({
			name: 'P9',
			promedio: parseFloat(promedio9.toFixed(2)),
		});
		arrayResultados.push({
			name: 'P10',
			promedio: parseFloat(promedio10.toFixed(2)),
		});
		arrayResultados.push({
			name: 'P11',
			promedio: parseFloat(promedio11.toFixed(2)),
		});
		arrayResultados.push({
			name: 'P12',
			promedio: parseFloat(promedio12.toFixed(2)),
		});
		arrayResultados.push({
			name: 'P13',
			promedio: parseFloat(promedio13.toFixed(2)),
		});
		arrayResultados.push({
			name: 'P14',
			promedio: parseFloat(promedio14.toFixed(2)),
		});

		setRegistros(array.length);
		setSatisfaccionGeneral(arrayResultados);
	};

	useEffect(() => {
		get();
		getNombre();
		const dbRef = ref(db, 'encuestaDeSatisfaccionComercial/');
		onChildChanged(dbRef, (data) => {
			get();
		});
	}, []);

	const getNombre = async () => {
		const data = await getCollectionsComplete(`usuarios/${id}`);
		setNombre(data.nombre);
	};

	const CustomTooltip = ({ active, payload, label }) => {
		if (active && payload && payload.length) {
			return (
				<div className='custom-tooltip'>
					<p className='label'>{`${label} : ${payload[0].value}`}</p>
				</div>
			);
		}
		return null;
	};

	return (
		<>
			<div className='content-area'>
				<SectionTitle title={`Reporte detallado`} urlPadre={['Comercial consultor', '/admin/comercial/consultor']} />
				<section id='reportedetalladoConsultor' className='mt30' ref={reference} style={{ padding: '15px' }}>
					<Grid container direction='row' alignItems='stretch' spacing={4}>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<h1>{`${nombre}`}</h1>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<ResponsiveContainer width='100%' height={350}>
								<BarChart data={satisfaccionGeneral}>
									<CartesianGrid strokeDasharray='3 3' />
									<XAxis dataKey='name'></XAxis>
									<YAxis domain={[0, 5]} ticks={[1, 2, 3, 4, 5]} />
									<Tooltip content={<CustomTooltip />} />
									<Bar barSize={150} maxBarSize={150} dataKey='promedio' position='centerTop'>
										{satisfaccionGeneral.map((entry, index) => (
											<>
												<Cell style={{ cursor: 'pointer' }} onClick={(e) => console.log(entry.id)} key={`cell-${index}`} fill={COLORS[(Math.random() * COLORS.length) | 0]} />
											</>
										))}
										<LabelList fill='#000' dataKey='promedio' position='centerTop' />
									</Bar>
								</BarChart>
							</ResponsiveContainer>
						</Grid>
						<Grid item lg={12} md={12} sm={12} xs={12} className='center'>
							<h3>{`Encuestas totales: ${total}`}</h3>
							<h3>{`Encuestas contestadas: ${registros}`}</h3>
						</Grid>
						<Nomenglatura />
					</Grid>
				</section>
				<Grid container direction='row' alignItems='stretch' spacing={4}>
					<Grid item lg={6} md={6} sm={12} xs={12} className='center'>
						<div variant='info' className='btn' onClick={getImage}>
							Descargar gráfica
						</div>
					</Grid>
					<Grid item lg={6} md={6} sm={12} xs={12} className='center'>
						<div variant='info' className='btn' onClick={() => reporteConsultoresExcel(id)}>
							Descargar excel
						</div>
					</Grid>
				</Grid>
			</div>
		</>
	);
}
