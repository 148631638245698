import { useState, useEffect } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../../config/firebase';
import { validateEmail, updateData, searchDataByValue, getDate } from '../../../assets/js/functions';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Container } from '@mui/system';
import Grid from '@mui/system/Unstable_Grid';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import FilledInput from '@mui/material/FilledInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Logo from '../../../assets/images/Spira-Color.png';
import './styles.scss';

export default function Login() {
	const [campos, setCampos] = useState({});
	const navigate = useNavigate();

	useEffect(() => {
		// removeElementsByClass('theme-toggle-btn');
	}, []);

	const validar = async (e) => {
		e.preventDefault();
		if (campos.correo) {
			if (!validateEmail(campos.correo)) {
				Swal.fire({
					title: '¡Oooopss!',
					text: 'Ingresa un correo valido',
					icon: 'warning',
					confirmButtonText: 'Cerrar',
					confirmButtonColor: '#c1212a',
				});
				return null;
			}
		} else {
			Swal.fire({
				title: '¡Oooopss!',
				text: 'El campo correo electrónico esta vacio',
				icon: 'warning',
				confirmButtonText: 'Cerrar',
				confirmButtonColor: '#c1212a',
			});
			return null;
		}

		const minusculas = campos.correo.toLowerCase();
		sendPasswordResetEmail(auth, minusculas)
			.then((resp) => {
				Swal.fire({
					title: '¡Bien hecho!',
					text: 'El correo se envio correctamente',
					icon: 'success',
					confirmButtonText: 'Cerrar',
					confirmButtonColor: '#c1212a',
				}).then((result) => {
					navigate('/');
				});
			})
			.catch((error) => {
				const errorCode = error.code;
				const errorMessage = error.message;
				console.log(error);
				console.log('Error ' + errorMessage);
				Swal.fire({
					title: 'Error',
					text: 'Contacta a un administrador',
					icon: 'error',
					confirmButtonText: 'Cerrar',
					confirmButtonColor: '#e00612',
				});
			});
	};

	return (
		<div id='login'>
			<Container maxWidth='md'>
				<div className='container-form-login'>
					<form onSubmit={validar}>
						<Grid container direction='row' alignItems='center' justifyContent='center' spacing={2}>
							<Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
								<Grid item lg={7} md={7} sm={12} xs={12}>
									<div className='full center'>
										<img src={Logo} alt='logo' style={{ width: '300px' }} />
									</div>
								</Grid>
								<Grid item lg={7} md={7} sm={12} xs={12}>
									<div className='full center welcome'>Recuperar contraseña</div>
									&nbsp;
									<div className='full center mb-60 subtitle'>Déjanos tu correo, te enviaremos un mail con las instrucciones para restablecer tu contraseña.</div>
								</Grid>
								<Grid item xs={12} sm={12} md={7} lg={7}>
									<FormControl fullWidth variant='filled' onChange={(e) => setCampos({ ...campos, correo: e.target.value })}>
										<InputLabel htmlFor='outlined-adornment-user'>Correo electrónico</InputLabel>
										<FilledInput
											id='outlined-adornment-user'
											type='email'
											autoComplete={false}
											endAdornment={
												<InputAdornment position='end'>
													<AlternateEmailIcon />
												</InputAdornment>
											}
											label='Usuario'
										/>
									</FormControl>
								</Grid>

								<Grid item xs={12} sm={12} md={7} lg={7}>
									<div className='full center space-footer-form'>
										<Button className='btn btn-yellow' type='submit' style={{ borderRadius: '20px' }}>
											Restablecer
										</Button>
									</div>
								</Grid>
								<Grid item xs={12} sm={12} md={7} lg={7}>
									<div className='center full reset-password' onClick={() => navigate('/')}>
										Regresar
									</div>
								</Grid>
							</Grid>
						</Grid>
					</form>
				</div>
			</Container>
		</div>
	);
}
