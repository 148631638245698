import React, { useState, useEffect } from 'react';
// material-ui
import { Container, Grid, TextField, MenuItem, InputLabel, FormControl, RadioGroup, FormControlLabel, Radio, Button } from '@mui/material';
import Swal from 'sweetalert2';
// project imports
import { setData, getDate, top } from '../../../assets/js/functions';
import uniqid from 'uniqid';
import Logo from '../../../assets/images/login/logo.png';
import '../SatisfaccionGeneral/styles.scss';

export default function SatisfaccionGeneral() {
	const [datos, setDatos] = useState({});
	const [seccionVisible, setSeccionVisible] = useState(1);
	const [otroPais, setOtroPais] = useState(false);

	useEffect(() => {
		top();
		// removeElementsByClass('theme-toggle-btn');
	}, []);

	const registrar = () => {
		const id = uniqid();
		const json = {
			...datos,
			id,
			fechaRegistro: getDate(),
		};
		if (Object.keys(json).length < 22) {
			Swal.fire({
				icon: 'warning',
				title: 'Cuidado',
				text: 'Tienes preguntas sin contestar',
				confirmButtonColor: '#e10613',
			});
		} else {
			setData('satisfaccionOperaciones', id, json).then(() => {
				Swal.fire({
					icon: 'success',
					title: 'Bien Hecho',
					text: 'Datos guardados exitosamente...',
					confirmButtonColor: '#e10613',
				}).then((result) => {
					if (result.isConfirmed) {
						window.location.reload();
					}
				});
			});
		}
	};

	const setPaisCliente = (pais) => {
		if (pais === 'Otro') {
			setOtroPais(true);
		} else {
			setOtroPais(false);
			setDatos({ ...datos, preguntaDos: pais });
		}
	};

	const cambiarSeccion = () => {
		top();
		setSeccionVisible(seccionVisible + 1);
	};

	let backgroundStyles = '';
	backgroundStyles += `#root::after {background-color: #fff }`;

	return (
		<>
			<style>{backgroundStyles}</style>
			<div className='header-free'>
				<img src={Logo} alt='logo' />
			</div>
			<Container maxWidth='lg' style={{ marginBottom: '40px' }}>
				<Grid container justifyContent='center' alignItems='center' spacing={4}>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<div className='titulo-encuesta'>Satisfacción Operaciones</div>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<>
							<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial' }}>
								Por favor responda las siguientes preguntas según su apreciación de nuestro servicio, siendo 1 la calificación más baja.
							</InputLabel>
						</>
					</Grid>
					{/* seccionVisible 1 */}
					{seccionVisible === 1 && (
						<>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<>
									<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
										1.Por favor indique la empresa a la que pertenece
									</InputLabel>
									<TextField label='' required fullWidth value={datos.preguntaUno} variant='outlined' onChange={(e) => setDatos({ ...datos, preguntaUno: e.target.value })} />
								</>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<>
									<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
										2.País de Operación del Cliente
									</InputLabel>
									<TextField label='' required fullWidth select variant='outlined' onChange={(e) => setPaisCliente(e.target.value)} value={datos.preguntaDos}>
										<MenuItem value='Argentina'>Argentina</MenuItem>
										<MenuItem value='Bolivia'>Bolivia</MenuItem>
										<MenuItem value='Brasil'>Brasil</MenuItem>
										<MenuItem value='Chile'>Chile</MenuItem>
										<MenuItem value='Colombia'>Colombia</MenuItem>
										<MenuItem value='Costa Rica'>Costa Rica</MenuItem>
										<MenuItem value='Cuba'>Cuba</MenuItem>
										<MenuItem value='Ecuador'>Ecuador</MenuItem>
										<MenuItem value='El Salvador'>El Salvador</MenuItem>
										<MenuItem value='España'>España</MenuItem>
										<MenuItem value='Estados Unidos'>Estados Unidos</MenuItem>
										<MenuItem value='Granada'>Granada</MenuItem>
										<MenuItem value='Guatemala'>Guatemala</MenuItem>
										<MenuItem value='Guayana'>Guayana</MenuItem>
										<MenuItem value='Guayana Francesa'>Guayana Francesa</MenuItem>
										<MenuItem value='Haití'>Haití</MenuItem>
										<MenuItem value='Honduras'>Honduras</MenuItem>
										<MenuItem value='Jamaica'>Jamaica</MenuItem>
										<MenuItem value='México'>México</MenuItem>
										<MenuItem value='Nicaragua'>Nicaragua</MenuItem>
										<MenuItem value='Panamá'>Panamá</MenuItem>
										<MenuItem value='Paraguay'>Paraguay</MenuItem>
										<MenuItem value='Perú'>Perú</MenuItem>
										<MenuItem value='Puerto Rico'>Puerto Rico</MenuItem>
										<MenuItem value='República Dominicana'>República Dominicana</MenuItem>
										<MenuItem value='Surinam'>Surinam</MenuItem>
										<MenuItem value='Uruguay'>Uruguay</MenuItem>
										<MenuItem value='Venezuela'>Venezuela</MenuItem>
										<MenuItem value='Otro'>Otro</MenuItem>
									</TextField>
									&nbsp;
									{otroPais && <TextField label='' required fullWidth helperText='Ingresa el nombre del país' variant='outlined' onChange={(e) => setDatos({ ...datos, preguntaDos: e.target.value, otroPais: true })} />}
								</>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<>
									<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
										3.País de <span className='bold'>CONSULTOR COMERCIAL</span>
									</InputLabel>
									<TextField label='' required fullWidth select variant='outlined' onChange={(e) => setDatos({ ...datos, preguntaTres: e.target.value })} value={datos.preguntaTres}>
										<MenuItem value='Colombia'>Colombia</MenuItem>
										<MenuItem value='Guatemala'>Guatemala</MenuItem>
										<MenuItem value='México'>México</MenuItem>
										<MenuItem value='Perú'>Perú</MenuItem>
									</TextField>
								</>
							</Grid>

							<Grid item xs={12} sm={12} md={12} lg={12}>
								<>
									<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
										4.Por favor indique el nombre del proyecto a evaluar
									</InputLabel>
									<TextField label='' required fullWidth variant='outlined' onChange={(e) => setDatos({ ...datos, preguntaCuatro: e.target.value })} value={datos.preguntaCuatro} />
								</>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<>
									<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
										5.¿El equipo de trabajo asignado se ajusta ágilmente a los cambios de la operación?
									</InputLabel>
									<FormControl>
										<RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' onChange={(e) => setDatos({ ...datos, preguntaCinco: e.target.value })} value={datos.preguntaCinco}>
											<FormControlLabel
												value='1'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='1'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='2'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='2'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='3'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='3'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='4'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',

															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='4'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='5'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='5'
												labelPlacement='bottom'
											/>
										</RadioGroup>
									</FormControl>
								</>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<>
									<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
										6.¿El equipo de trabajo asignado genera propuestas que agregan valor a la operación?
									</InputLabel>
									<FormControl>
										<RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' onChange={(e) => setDatos({ ...datos, preguntaSeis: e.target.value })} value={datos.preguntaSeis}>
											<FormControlLabel
												value='1'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='1'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='2'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='2'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='3'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='3'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='4'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',

															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='4'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='5'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='5'
												labelPlacement='bottom'
											/>
										</RadioGroup>
									</FormControl>
								</>
							</Grid>
						</>
					)}
					{/* seccionVisible 2 */}
					{seccionVisible === 2 && (
						<>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<>
									<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
										7.¿El equipo de trabajo asignado es apreciado por el público objetivo de su organización?
									</InputLabel>
									<FormControl>
										<RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' onChange={(e) => setDatos({ ...datos, preguntaSiete: e.target.value })} value={datos.preguntaSiete}>
											<FormControlLabel
												value='1'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='1'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='2'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='2'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='3'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='3'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='4'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',

															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='4'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='5'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='5'
												labelPlacement='bottom'
											/>
										</RadioGroup>
									</FormControl>
								</>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<>
									<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
										8.¿Para su organización es valiosa la gestión de SPIRA y su equipo de trabajo en el logro de sus objetivos?
									</InputLabel>
									<FormControl>
										<RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' onChange={(e) => setDatos({ ...datos, preguntaOcho: e.target.value })} value={datos.preguntaOcho}>
											<FormControlLabel
												value='1'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='1'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='2'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='2'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='3'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='3'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='4'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',

															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='4'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='5'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='5'
												labelPlacement='bottom'
											/>
										</RadioGroup>
									</FormControl>
								</>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<>
									<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
										9.¿SPIRA ha cumplido con la oferta de valor pactada en los acuerdos de servicio/contrato?
									</InputLabel>
									<FormControl>
										<RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' onChange={(e) => setDatos({ ...datos, preguntaNueve: e.target.value })} value={datos.preguntaNueve}>
											<FormControlLabel
												value='1'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='1'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='2'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='2'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='3'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='3'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='4'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',

															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='4'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='5'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='5'
												labelPlacement='bottom'
											/>
										</RadioGroup>
									</FormControl>
								</>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<>
									<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
										10.¿En la operación se siente la presencia de SPIRA como proveedor y como empresa?
									</InputLabel>
									<FormControl>
										<RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' onChange={(e) => setDatos({ ...datos, preguntaDiez: e.target.value })} value={datos.preguntaDiez}>
											<FormControlLabel
												value='1'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='1'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='2'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='2'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='3'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='3'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='4'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',

															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='4'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='5'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='5'
												labelPlacement='bottom'
											/>
										</RadioGroup>
									</FormControl>
								</>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<>
									<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
										11.¿El equipo de trabajo asignado ha generado empatía en todo el proceso de implementación del proyecto?
									</InputLabel>
									<FormControl>
										<RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' onChange={(e) => setDatos({ ...datos, preguntaOnce: e.target.value })} value={datos.preguntaOnce}>
											<FormControlLabel
												value='1'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='1'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='2'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='2'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='3'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='3'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='4'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',

															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='4'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='5'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='5'
												labelPlacement='bottom'
											/>
										</RadioGroup>
									</FormControl>
								</>
							</Grid>
						</>
					)}
					{/* seccionVisible 3 */}
					{seccionVisible === 3 && (
						<>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<>
									<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
										12.¿El equipo de trabajo asignado definió un plan o cronograma de trabajo, donde especificó las fechas de entrega(parcial o total) del proyecto y criterios de aceptación de este por parte del cliente?
									</InputLabel>
									<FormControl>
										<RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' onChange={(e) => setDatos({ ...datos, preguntaDoce: e.target.value })} value={datos.preguntaDoce}>
											<FormControlLabel
												value='1'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='1'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='2'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='2'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='3'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='3'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='4'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',

															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='4'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='5'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='5'
												labelPlacement='bottom'
											/>
										</RadioGroup>
									</FormControl>
								</>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<>
									<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
										13.¿El equipo de trabajo asignado genera confianza por la claridad y conocimiento de las necesidades y expectativas del proyecto?
									</InputLabel>
									<FormControl>
										<RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' onChange={(e) => setDatos({ ...datos, preguntaTrece: e.target.value })} value={datos.preguntaTrece}>
											<FormControlLabel
												value='1'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='1'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='2'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='2'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='3'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='3'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='4'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',

															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='4'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='5'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='5'
												labelPlacement='bottom'
											/>
										</RadioGroup>
									</FormControl>
								</>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<>
									<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
										14.¿El equipo de trabajo asignado presentó una secuencia de actividades detallada e ilustrativa del proyecto a desarrollar?
									</InputLabel>
									<FormControl>
										<RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' onChange={(e) => setDatos({ ...datos, preguntaCatorce: e.target.value })} value={datos.preguntaCatorce}>
											<FormControlLabel
												value='1'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='1'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='2'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='2'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='3'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='3'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='4'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',

															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='4'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='5'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='5'
												labelPlacement='bottom'
											/>
										</RadioGroup>
									</FormControl>
								</>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<>
									<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
										15.¿El equipo SPIRA cuenta con los recursos humanos, técnicos y metodológicos necesarios para la ejecución del proyecto?
									</InputLabel>
									<FormControl>
										<RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' onChange={(e) => setDatos({ ...datos, preguntaQuince: e.target.value })} value={datos.preguntaQuince}>
											<FormControlLabel
												value='1'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='1'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='2'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='2'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='3'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='3'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='4'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',

															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='4'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='5'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='5'
												labelPlacement='bottom'
											/>
										</RadioGroup>
									</FormControl>
								</>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<>
									<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
										16.¿El equipo de trabajo asignado demuestra tener los conocimientos y experiencia requeridos para la ejecución del proyecto?
									</InputLabel>
									<FormControl>
										<RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' onChange={(e) => setDatos({ ...datos, preguntaDieciseis: e.target.value })} value={datos.preguntaDieciseis}>
											<FormControlLabel
												value='1'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='1'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='2'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='2'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='3'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='3'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='4'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',

															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='4'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='5'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='5'
												labelPlacement='bottom'
											/>
										</RadioGroup>
									</FormControl>
								</>
							</Grid>
						</>
					)}
					{/* seccionVisible 4 */}
					{seccionVisible === 4 && (
						<>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<>
									<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
										17.¿El equipo de trabajo asignado realiza un seguimiento permanente y periódico del proyecto, de modo que se atiendan las inquietudes y requerimientos solicitados?
									</InputLabel>
									<FormControl>
										<RadioGroup
											row
											aria-labelledby='demo-row-radio-buttons-group-label'
											name='row-radio-buttons-group'
											onChange={(e) =>
												setDatos({
													...datos,
													preguntaDiecisiete: e.target.value,
												})
											}
											value={datos.preguntaDiecisiete}
										>
											<FormControlLabel
												value='1'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='1'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='2'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='2'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='3'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='3'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='4'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',

															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='4'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='5'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='5'
												labelPlacement='bottom'
											/>
										</RadioGroup>
									</FormControl>
								</>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<>
									<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
										18.¿El equipo de trabajo asignado hace las entregas pactadas con calidad y de acuerdo con las expectativas planteadas al inicio del proyecto?
									</InputLabel>
									<FormControl>
										<RadioGroup
											row
											aria-labelledby='demo-row-radio-buttons-group-label'
											name='row-radio-buttons-group'
											onChange={(e) =>
												setDatos({
													...datos,
													preguntaDieciocho: e.target.value,
												})
											}
											value={datos.preguntaDieciocho}
										>
											<FormControlLabel
												value='1'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='1'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='2'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='2'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='3'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='3'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='4'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',

															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='4'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='5'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='5'
												labelPlacement='bottom'
											/>
										</RadioGroup>
									</FormControl>
								</>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<>
									<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
										19.¿La solución presentada cumple con sus expectativas en términos de calidad e innovación?
									</InputLabel>
									<FormControl>
										<RadioGroup
											row
											aria-labelledby='demo-row-radio-buttons-group-label'
											name='row-radio-buttons-group'
											onChange={(e) =>
												setDatos({
													...datos,
													preguntaDiecinueve: e.target.value,
												})
											}
											value={datos.preguntaDiecinueve}
										>
											<FormControlLabel
												value='1'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='1'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='2'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='2'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='3'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='3'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='4'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',

															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='4'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='5'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='5'
												labelPlacement='bottom'
											/>
										</RadioGroup>
									</FormControl>
								</>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<>
									<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
										20.En una escala de 1 a 10, ¿En qué medida estaría dispuesto a recomendar SPIRA a sus conocidos?
									</InputLabel>
									<FormControl>
										<RadioGroup
											row
											aria-labelledby='demo-row-radio-buttons-group-label'
											name='row-radio-buttons-group'
											onChange={(e) =>
												setDatos({
													...datos,
													preguntaVeinte: e.target.value,
												})
											}
											value={datos.preguntaVeinte}
										>
											<FormControlLabel
												value='1'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='1'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='2'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='2'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='3'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='3'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='4'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',

															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='4'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='5'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='5'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='6'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='6'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='7'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='7'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='8'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='8'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='9'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='9'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='10'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='10'
												labelPlacement='bottom'
											/>
										</RadioGroup>
									</FormControl>
								</>
							</Grid>
						</>
					)}

					{/* Botones */}
					<Grid item xs={12} sm={12} md={12} lg={12}>
						{seccionVisible > 1 && (
							<Button className='blanco' variant='contained' style={{ background: '#e10613' }} onClick={() => setSeccionVisible(seccionVisible - 1)}>
								Regresar
							</Button>
						)}
						&nbsp;
						<Button className='blanco' variant='contained' style={{ background: '#e10613' }} onClick={() => (seccionVisible !== 4 ? cambiarSeccion() : registrar())}>
							{seccionVisible !== 4 ? 'Siguiente' : 'Enviar'}
						</Button>
					</Grid>
				</Grid>
			</Container>
		</>
	);
}
