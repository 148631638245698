import { useEffect } from 'react';
import { Container, Grid } from '@mui/material';
// import { removeElementsByClass } from '../../assets/js/functions';
import img from '../../assets/images/404.jpeg';
import Logo from '../../assets/images/login/logo.png';

export default function ErrorPublica() {
	useEffect(() => {
		// removeElementsByClass('theme-toggle-btn');
	}, []);

	let backgroundStyles = '';
	backgroundStyles += `#root::after {background-color: #fff }`;
	return (
		<>
			<style>{backgroundStyles}</style>
			<div className='header-free'>
				<img src={Logo} alt='logo' />
			</div>
			<Container maxWidth='lg' style={{ marginBottom: '40px' }}>
				<Grid container justifyContent='center' alignItems='center' spacing={4}>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<h1 className='titulo-encuesta'>¡Oooops!</h1>
						<h1 className='titulo-encuesta'>Página no encontrada</h1>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<img src={img} alt='404' className='full' />
					</Grid>
				</Grid>
			</Container>
		</>
	);
}
