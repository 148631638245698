import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import uniqid from 'uniqid';
import orderBy from 'lodash.orderby';
import Grid from '@mui/system/Unstable_Grid';
import { TextField, MenuItem, InputLabel } from '@mui/material';
import SectionTitle from '../../../components/SeccionTitle';
import { getDate, setData, getCollections } from '../../../assets/js/functions';

export default function EncuestaConsultorComercial() {
	const [datos, setDatos] = useState({});
	const [consultores, setConsultores] = useState([]);
	const [otroPais, setOtroPais] = useState(false);
	const [otroConsultor, setOtroConsultor] = useState(false);

	useEffect(() => {
		getConsultores();
	}, []);

	const getConsultores = async () => {
		let array = [{ nombre: 'Otro' }],
			ordenados = [];
		const data = await getCollections('usuarios');
		Object.values(data).map((val) => {
			if (val.listaConsultores) {
				array.push(val);
			}
		});
		if (Object.keys(array).length > 0) {
			ordenados = orderBy(array, ['nombre'], ['asc']);
		}
		setConsultores(ordenados);
	};

	const setPaisCliente = (pais) => {
		if (pais === 'Otro') {
			setOtroPais(true);
		} else {
			setOtroPais(false);
			setDatos({ ...datos, preguntaDos: pais });
		}
	};

	const setConsultorLista = (nombre) => {
		if (nombre === 'Otro') {
			setOtroConsultor(true);
		} else {
			setOtroConsultor(false);
			setDatos({ ...datos, consultore: nombre });
		}
	};

	const guardar = () => {
		const id = uniqid();
		if (Object.keys(datos).length < 4) {
			Swal.fire({
				icon: 'warning',
				title: 'Espera',
				html: `Aun tienes preguntas sin contestar`,
				confirmButtonColor: '#e10613',
			});
			return null;
		}
		const json = {
			...datos,
			id,
			idUsuario: sessionStorage.getItem('3c393f98'),
			fechaRegistroConsultor: getDate(),
			status: 'Sin abrir',
			rol: 'comercial',
		};
		setData('encuestaDeSatisfaccionComercial', id, json)
			.then(() => {
				Swal.fire({
					icon: 'success',
					title: 'Bien Hecho',
					html: `<p>Comparte el siguiente link al cliente para completar la encuesta</p><p>https://bi-spira.web.app/encuesta-de-satisfaccion-comercial/${id}</p>`,
					confirmButtonColor: '#e10613',
					confirmButtonText: 'Copiar link',
				}).then((result) => {
					if (result.isConfirmed) {
						navigator.clipboard.writeText(`https://bi-spira.web.app/encuesta-de-satisfaccion-comercial/${id}`);
						window.location.reload();
					}
				});
			})
			.catch((e) => console.log(e));
	};

	return (
		<div className='content-area'>
			<SectionTitle title='Encuesta de satisfacción comercial' urlPadre={['Encuestas', '/admin/encuestas/encuesta-de-satisfaccion-comercial/lista']} />
			<section id='dashboard'>
				<Grid container direction='row' spacing={2}>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<>
							<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
								1.Por favor indique la empresa a la que pertenece
							</InputLabel>
							<TextField label='' required fullWidth value={datos.preguntaUno} variant='outlined' onChange={(e) => setDatos({ ...datos, preguntaUno: e.target.value })} />
						</>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<>
							<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
								2.País de Operación del Cliente
							</InputLabel>
							<TextField label='' required fullWidth select variant='outlined' onChange={(e) => setPaisCliente(e.target.value)} value={datos.preguntaDos}>
								<MenuItem value='Argentina'>Argentina</MenuItem>
								<MenuItem value='Bolivia'>Bolivia</MenuItem>
								<MenuItem value='Brasil'>Brasil</MenuItem>
								<MenuItem value='Chile'>Chile</MenuItem>
								<MenuItem value='Colombia'>Colombia</MenuItem>
								<MenuItem value='Costa Rica'>Costa Rica</MenuItem>
								<MenuItem value='Cuba'>Cuba</MenuItem>
								<MenuItem value='Ecuador'>Ecuador</MenuItem>
								<MenuItem value='El Salvador'>El Salvador</MenuItem>
								<MenuItem value='España'>España</MenuItem>
								<MenuItem value='Estados Unidos'>Estados Unidos</MenuItem>
								<MenuItem value='Granada'>Granada</MenuItem>
								<MenuItem value='Guatemala'>Guatemala</MenuItem>
								<MenuItem value='Guayana'>Guayana</MenuItem>
								<MenuItem value='Guayana Francesa'>Guayana Francesa</MenuItem>
								<MenuItem value='Haití'>Haití</MenuItem>
								<MenuItem value='Honduras'>Honduras</MenuItem>
								<MenuItem value='Jamaica'>Jamaica</MenuItem>
								<MenuItem value='México'>México</MenuItem>
								<MenuItem value='Nicaragua'>Nicaragua</MenuItem>
								<MenuItem value='Panamá'>Panamá</MenuItem>
								<MenuItem value='Paraguay'>Paraguay</MenuItem>
								<MenuItem value='Perú'>Perú</MenuItem>
								<MenuItem value='Puerto Rico'>Puerto Rico</MenuItem>
								<MenuItem value='República Dominicana'>República Dominicana</MenuItem>
								<MenuItem value='Surinam'>Surinam</MenuItem>
								<MenuItem value='Uruguay'>Uruguay</MenuItem>
								<MenuItem value='Venezuela'>Venezuela</MenuItem>
								<MenuItem value='Otro'>Otro</MenuItem>
							</TextField>
							&nbsp;
							{otroPais && <TextField label='' required fullWidth helperText='Ingresa el nombre del país' variant='outlined' onChange={(e) => setDatos({ ...datos, preguntaDos: e.target.value, otroPais: true })} />}
						</>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<>
							<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
								3.País de <span className='bold'>CONSULTOR COMERCIAL</span>
							</InputLabel>
							<TextField label='' required fullWidth select variant='outlined' onChange={(e) => setDatos({ ...datos, preguntaTres: e.target.value })} value={datos.preguntaTres}>
								<MenuItem value='Colombia'>Colombia</MenuItem>
								<MenuItem value='Guatemala'>Guatemala</MenuItem>
								<MenuItem value='México'>México</MenuItem>
								<MenuItem value='Perú'>Perú</MenuItem>
							</TextField>
						</>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<>
							<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
								4.Por favor indique el nombre del proyecto a evaluar
							</InputLabel>
							<TextField label='' required fullWidth variant='outlined' onChange={(e) => setDatos({ ...datos, preguntaCuatro: e.target.value })} value={datos.preguntaCuatro} />
						</>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<>
							<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
								5.Nombre del CONSULTOR
							</InputLabel>
							{/* <TextField label='' required fullWidth variant='outlined' onChange={(e) => setDatos({ ...datos, preguntaCuatro: e.target.value })} value={datos.preguntaCuatro}> */}
							<TextField label='' required fullWidth select variant='outlined' onChange={(e) => setConsultorLista(e.target.value)} value={datos.consultore}>
								{Object.values(consultores).map((val, index) => (
									<MenuItem key={index} value={val.nombre}>
										{val.nombre}
									</MenuItem>
								))}
							</TextField>
							&nbsp;
							{otroConsultor && <TextField label='' required fullWidth helperText='Ingresa el nombre' variant='outlined' onChange={(e) => setDatos({ ...datos, consultor: e.target.value })} />}
						</>
					</Grid>
				</Grid>
				<Grid container direction='row' spacing={2}>
					<Grid item xs={12} sm={12} md={12} lg={2}>
						<div className='btn' style={{ marginTop: '10px' }} onClick={guardar}>
							Guardar
						</div>
					</Grid>
				</Grid>
			</section>
		</div>
	);
}
