import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Breadcrumbs, Typography, Link } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import './styles.scss';

export default function Breadcrumb({ links, actual }) {
	const navigate = useNavigate();

	const goBack = (param) => {
		navigate(`${param}`);
	};

	return (
		<div>
			<Breadcrumbs id='Breadcrumb' aria-label='breadcrumb'>
				{links.map((link, index) => (
					<Link key={index} underline='hover' color='inherit' sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => goBack(link[1])}>
						{index === 0 && <HomeIcon sx={{ mr: 0.5 }} fontSize='inherit' />}
						{link[0]}
					</Link>
				))}
				<Typography>{actual}</Typography>
			</Breadcrumbs>
			{/* <div className='breadcrumbFondo'></div> */}
		</div>
	);
}
