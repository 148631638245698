import { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { DARK_THEME, LIGHT_THEME } from '../constants/themeConstants';

export const ThemeContext = createContext({});

export const ThemeProvider = ({ children }) => {
	const [theme, setTheme] = useState(sessionStorage.getItem('themeMode') !== null ? sessionStorage.getItem('themeMode') : 'light');
	sessionStorage.setItem('themeMode', theme); // storing in the session storage

	const toggleTheme = () => {
		setTheme((prevTheme) => (prevTheme === LIGHT_THEME ? DARK_THEME : LIGHT_THEME));
		sessionStorage.setItem('themeMode', theme);
	};

	return (
		<ThemeContext.Provider
			value={{
				theme,
				toggleTheme,
			}}
		>
			{children}
		</ThemeContext.Provider>
	);
};

ThemeProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
