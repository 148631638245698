import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdOutlineGridView, MdOutlineLogout, MdOutlineSettings } from 'react-icons/md';
import { AiOutlineSolution, AiOutlineForm, AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai';
import { stringToArray } from '../../../assets/js/functions';

export default function SuperAdministrador() {
	const navigate = useNavigate();
	const [open3, setOpen3] = useState(false);

	const getPathname = () => {
		const pathname = window.location.pathname;
		const array = stringToArray(pathname, '/');
		return array[2];
	};
	const abner = getPathname();

	const logout = () => {
		sessionStorage.clear();
		navigate('/');
	};

	const goUrl = (url) => {
		navigate(url);
	};

	return (
		<div className='sidebar-body'>
			<div className='sidebar-menu'>
				<ul className='menu-list'>
					<li className='menu-item'>
						<div onClick={() => goUrl('/admin/dashboard')} className={`${abner === 'dashboard' && 'active'} menu-link`}>
							<span className='menu-link-icon'>
								<MdOutlineGridView size={18} />
							</span>
							<span className='menu-link-text'>Dashboard</span>
						</div>
					</li>

					<li className='menu-item'>
						<div onClick={() => goUrl('/admin/comercial/consultor')} className={`${abner === 'comercial' && 'active'} menu-link `}>
							<span className='menu-link-icon'>
								<AiOutlineSolution size={20} />
							</span>
							<span className='menu-link-text'>Consultores</span>
						</div>
					</li>
					<li className='menu-item'>
						<div
							onClick={() => {
								setOpen3(!open3);
							}}
							className={` menu-link `}
						>
							<span className='menu-link-icon'>
								<AiOutlineForm size={20} />
							</span>
							<span className='menu-link-text'>
								Encuestas
								<span className='icon-menu'>{open3 ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}</span>
							</span>
						</div>
					</li>
					{open3 && (
						<>
							<li className='menu-item'>
								<div onClick={() => navigate('/admin/encuestas/encuesta-de-satisfaccion-comercial/lista')} className={`${window.location.pathname === '/admin/encuestas/encuesta-de-satisfaccion-comercial/lista' && 'active'} menu-link `}>
									<span className='menu-link-text' style={{ marginLeft: '30px' }}>
										Satisfacción comercial
									</span>
								</div>
							</li>
						</>
					)}
					{/* <li className='menu-item'>
						<div onClick={() => goUrl('/admin/encuestas/encuesta-de-satisfaccion-operativa/lista')} className={`${abner === 'encuestas' && 'active'} menu-link `}>
							<span className='menu-link-icon'>
								<AiOutlineForm size={20} />
							</span>
							<span className='menu-link-text'>Encuestas</span>
						</div>
					</li> */}
				</ul>
			</div>

			<div className='sidebar-menu sidebar-menu2'>
				<ul className='menu-list'>
					<li className='menu-item'>
						<div onClick={() => navigate('/admin/configuracion')} className={`${abner === 'configuracion' && 'active'} menu-link`}>
							<span className='menu-link-icon'>
								<MdOutlineSettings size={20} />
							</span>
							<span className='menu-link-text'>Configuración</span>
						</div>
					</li>
					<li className='menu-item'>
						<div onClick={logout} className='menu-link'>
							<span className='menu-link-icon'>
								<MdOutlineLogout size={20} />
							</span>
							<span className='menu-link-text'>Logout</span>
						</div>
					</li>
				</ul>
			</div>
		</div>
	);
}
