import React from 'react';
import { Typography } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid';

export default function Nomenglatura() {
	return (
		<>
			<Grid item xs={12} sm={12} md={12} lg={12} className='mb30'>
				<Typography color='text' colorBrightness='secondary'>
					P5 = ¿El Consultor Comercial cumplió en calidad con los compromisos adquiridos durante todo el proceso? (propuestas, citas, entregas, etc.)
				</Typography>
				<Typography color='text' colorBrightness='secondary'>
					P6 = ¿El Consultor Comercial cumplió en tiempo con los compromisos adquiridos durante todo el proceso? (propuestas, citas, entregas, etc.)
				</Typography>
				<Typography color='text' colorBrightness='secondary'>
					P7 = ¿El Consultor Comercial se tomo el tiempo de entender las necesidades de su negocio /operación cuando presentó la propuesta comercial?
				</Typography>
				<Typography color='text' colorBrightness='secondary'>
					P8 = ¿El Consultor Comercial evidenció los beneficios que obtendrá su organización en el aumento del desempeño y/o el cumplimiento de sus objetivos, a través de la propuesta comercial presentada?
				</Typography>
				<Typography color='text' colorBrightness='secondary'>
					P9 = ¿El Consultor Comercial presentó una propuesta que respondía a las necesidades planteadas?
				</Typography>
				<Typography color='text' colorBrightness='secondary'>
					P10 = ¿El Consultor Comercial mantuvo un permanente contacto e hizo seguimiento de la propuesta comercial presentada?
				</Typography>
				<Typography color='text' colorBrightness='secondary'>
					P11 = ¿El Consultor Comercial transmitió una imagen profesional correcta y agradable durante el proceso?
				</Typography>
				<Typography color='text' colorBrightness='secondary'>
					P12 = ¿El Consultor Comercial se comunicó de manera efectiva en el proceso?
				</Typography>
				<Typography color='text' colorBrightness='secondary'>
					P13 = ¿El Consultor Comercial creo un espacio agradable que hizo fácil la interacción?
				</Typography>
				<Typography color='text' colorBrightness='secondary'>
					P14 = ¿Le gustaría seguir siendo atendido por el Consultor Comercial asignado?
				</Typography>
			</Grid>
		</>
	);
}
