import React from 'react';
import ReactDOM from 'react-dom/client';
import Navigation from './navigation';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from './context/ThemeContext.jsx';
import { SidebarProvider } from './context/SidebarContext.jsx';
import './assets/sass/global.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<ThemeProvider>
			<SidebarProvider>
				<Navigation />
			</SidebarProvider>
		</ThemeProvider>
	</React.StrictMode>
);
reportWebVitals();
