import { getDate, updateData } from '../../assets/js/functions';

export default function ValidateUser({ children }) {
	const id = sessionStorage.getItem('3c393f98');
	const data = sessionStorage.getItem('8b6d1c1e');
	let content = '';

	if (data === '0242ac120002' || data === '76c66ae87579' || data === '051c0d485398') {
		const b = getDate();

		const json = {
			ultimaConexion: b,
		};
		updateData('usuarios', id, json);
		// console.log(b);
		content = <div>{children}</div>;
	} else {
		window.location.replace('/');
	}
	return content;
}
