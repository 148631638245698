// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  // pruebas
  // apiKey: "AIzaSyCOP3D-5cmaSuvUg6Hpv1edWYmG-rGAmXk",
  // authDomain: "bi-pruebas-a7abe.firebaseapp.com",
  // projectId: "bi-pruebas-a7abe",
  // storageBucket: "bi-pruebas-a7abe.appspot.com",
  // messagingSenderId: "163429082543",
  // appId: "1:163429082543:web:ad22a150934281b1efbb5a",

  // productiva
  apiKey: "AIzaSyBJPxrknN9qFupPmldSb6bRfVtqRSlVi7M",
  authDomain: "bi-spira.firebaseapp.com",
  databaseURL: "https://bi-spira-default-rtdb.firebaseio.com",
  projectId: "bi-spira",
  storageBucket: "bi-spira.appspot.com",
  messagingSenderId: "1044125364479",
  appId: "1:1044125364479:web:f51eff84bd84ab95b85cca",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app)
export const auth = getAuth(app);
