import React, { useState, useEffect } from 'react';
import { getAuth, updatePassword, signInWithEmailAndPassword } from 'firebase/auth';
import { Grid, TextField, Button } from '@mui/material';
import { AiFillCloseCircle, AiFillCheckSquare } from 'react-icons/ai';
import Swal from 'sweetalert2';
import SeccionTitle from '../../../components/SeccionTitle';
import { userActive, top, updateData } from '../../../assets/js/functions';
import profile from '../../../assets/images/perfil/profile.png';
import './styles.scss';

export default function Perfil() {
	const [usuario, setUsuario] = useState({});
	const [password, setPassword] = useState('');
	const [repetPassword, setRepetPassword] = useState('');
	const [iconPassword, setIconPassword] = useState({ tamano: false, mayuscula: false, minuscula: false, numero: false, caracterEspecial: false });
	const auth = getAuth();

	useEffect(() => {
		top();
		getUsuario();
	}, []);

	const getUsuario = async () => {
		const data = await userActive();
		setUsuario(data);
	};

	const validarPassword = () => {
		if (password === '' || repetPassword === '') {
			Swal.fire({
				icon: 'warning',
				title: `¡Advertencia!`,
				text: `Uno o dos de los campos de las contraseñas estan vacías`,
				confirmButtonColor: '#c1212a',
				confirmButtonText: 'Cerrar',
			});
			return null;
		}

		if (password !== repetPassword) {
			Swal.fire({
				icon: 'error',
				title: `¡Error!`,
				text: `Las contraseñas no son iguales`,
				confirmButtonColor: '#c1212a',
				confirmButtonText: 'Cerrar',
			});
			return null;
		}

		if (password === repetPassword) {
			if (iconPassword.tamano === true && iconPassword.mayuscula === true && iconPassword.minuscula === true && iconPassword.numero === true && iconPassword.caracterEspecial === true) {
				signInWithEmailAndPassword(auth, usuario.correo, 'Sp1r@*')
					.then((userCredential) => {
						console.log(userCredential);
						updatePassword(userCredential.user, password)
							.then(() => {
								Swal.fire({
									icon: 'success',
									title: 'Bien Hecho',
									text: 'La contraseña se cambio correctamente',
									confirmButtonColor: '#c1212a',
								}).then((result) => {
									if (result.isConfirmed) {
										updateData('usuarios', sessionStorage.getItem('3c393f98'), { cambiarContraseña: false }).then(() => {
											window.location.reload();
										});
									}
								});
							})
							.catch((error) => {
								console.log(error);
								Swal.fire({
									icon: 'error',
									title: 'Error',
									text: 'Contacta a un administrador',
									confirmButtonColor: '#c1212a',
								});
							});
					})
					.catch((error) => {
						console.log(error);
					});
			} else {
				Swal.fire({
					icon: 'error',
					title: `¡Error!`,
					text: `Por favor revisa que se cumplan los criterios de la contraseña`,
					confirmButtonColor: '#c1212a',
					confirmButtonText: 'Cerrar',
				});
				return null;
			}
		}
	};

	const evaluatePasswordStrength = (pass) => {
		if (!pass) {
			setIconPassword({ tamano: false, mayuscula: false, minuscula: false, numero: false, caracterEspecial: false });
			return '';
		}
		let copyState = { ...iconPassword };
		const minMaxLength = /^[\s\S]{8,32}$/,
			upper = /[A-Z]/,
			lower = /[a-z]/,
			number = /[0-9]/,
			special = /[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/;

		if (minMaxLength.test(pass)) {
			copyState = { ...copyState, tamano: true };
		} else {
			copyState = { ...copyState, tamano: false };
		}
		if (upper.test(pass)) {
			copyState = { ...copyState, mayuscula: true };
		} else {
			copyState = { ...copyState, mayuscula: false };
		}
		if (lower.test(pass)) {
			copyState = { ...copyState, minuscula: true };
		} else {
			copyState = { ...copyState, minuscula: false };
		}
		if (number.test(pass)) {
			copyState = { ...copyState, numero: true };
		} else {
			copyState = { ...copyState, numero: false };
		}
		if (special.test(pass)) {
			copyState = { ...copyState, caracterEspecial: true };
		} else {
			copyState = { ...copyState, caracterEspecial: false };
		}

		setIconPassword(copyState);
	};

	return (
		<div className='content-area'>
			<SeccionTitle title='Configuración' />
			<section id='perfil'>
				<Grid container direction='row' alignItems='center' justifyContent='center' spacing={5}>
					<Grid xs={12} sm={3} md={3} lg={3}>
						<div className='center'>
							<img src={profile} alt='img' className='full img-perfil mt30' />
						</div>
					</Grid>
				</Grid>
				<Grid container direction='row' justifyContent='center' spacing={2} className='mt30'>
					<Grid xs={12} sm={12} lg={6} md={6}>
						<TextField InputLabelProps={{ shrink: true }} fullWidth disabled label='Nombre' variant='outlined' value={usuario.nombre} />
					</Grid>
				</Grid>
				<Grid container direction='row' justifyContent='center' spacing={2} className='mt30'>
					<Grid xs={12} sm={12} lg={6} md={6}>
						<TextField InputLabelProps={{ shrink: true }} fullWidth disabled label='Correo electrónico' variant='outlined' value={usuario.correo} />
					</Grid>
				</Grid>
				<Grid container direction='row' justifyContent='center' spacing={2} className='mt30'>
					<Grid xs={12} sm={12} lg={6} md={6}>
						<TextField InputLabelProps={{ shrink: true }} fullWidth disabled label='Teléfono' variant='outlined' value={usuario.telefono} />
					</Grid>
				</Grid>
				<Grid container direction='row' justifyContent='center' spacing={2} className='mt30'>
					<Grid xs={12} sm={12} lg={6} md={6}>
						<TextField InputLabelProps={{ shrink: true }} fullWidth disabled label='País' variant='outlined' value={usuario.pais} />
					</Grid>
				</Grid>

				{usuario.cambiarContraseña && (
					<>
						<Grid container direction='row' justifyContent='center' spacing={2} className='mt30'>
							<Grid xs={12} sm={12} lg={6} md={6} data-aos='zoom-in-up' data-aos-delay='100'>
								<div className=''>Por favor cambia tu contraseña por una mas segura</div>
							</Grid>
						</Grid>
						<Grid container direction='row' justifyContent='center' spacing={2} className='mt30'>
							<Grid xs={12} sm={12} lg={6} md={6} data-aos='zoom-in-up' data-aos-delay='100'>
								<TextField
									required
									fullWidth
									label='Contraseña'
									variant='outlined'
									onChange={(e) => {
										setPassword(e.target.value);
										evaluatePasswordStrength(e.target.value);
									}}
								/>
							</Grid>
						</Grid>
						<Grid container direction='row' justifyContent='center' spacing={2} className='mt30'>
							<Grid xs={12} sm={12} lg={6} md={6} data-aos='zoom-in-up' data-aos-delay='100'>
								<TextField required fullWidth label='Repetir contraseña' variant='outlined' onChange={(e) => setRepetPassword(e.target.value)} />
							</Grid>
						</Grid>
						<Grid container direction='row' justifyContent='center' spacing={2} className='mt30'>
							<Grid xs={12} sm={12} lg={6} md={6} data-aos='zoom-in-up' data-aos-delay='100'>
								<div class='password-check'>
									<div class='check-length'>{iconPassword.tamano ? <AiFillCheckSquare style={{ color: '#00b14a' }} /> : <AiFillCloseCircle style={{ color: '#c70039' }} />} Debe tener mínimo 8 caracteres</div>
									<div class='check-uppercase'>{iconPassword.mayuscula ? <AiFillCheckSquare style={{ color: '#00b14a' }} /> : <AiFillCloseCircle style={{ color: '#c70039' }} />} Debe tener mínimo una letra mayúscula (A-Z)</div>
									<div class='check-lowercase'>{iconPassword.minuscula ? <AiFillCheckSquare style={{ color: '#00b14a' }} /> : <AiFillCloseCircle style={{ color: '#c70039' }} />} Debe tener mínimo una minúscula (a-z)</div>
									<div class='check-number'>{iconPassword.numero ? <AiFillCheckSquare style={{ color: '#00b14a' }} /> : <AiFillCloseCircle style={{ color: '#c70039' }} />} Debe tener mínimo un número (0-9)</div>
									<div class='check-special'>{iconPassword.caracterEspecial ? <AiFillCheckSquare style={{ color: '#00b14a' }} /> : <AiFillCloseCircle style={{ color: '#c70039' }} />} Debe tener mínimo un carácter especial (@-$)</div>
								</div>
							</Grid>
						</Grid>
						<Grid container direction='row' justifyContent='center' spacing={2} className='mt30'>
							<Grid xs={12} sm={12} lg={6} md={6} data-aos='zoom-in-up' data-aos-delay='100'>
								<Button className='btn' onClick={validarPassword}>
									Actualizar
								</Button>
							</Grid>
						</Grid>
					</>
				)}
			</section>
		</div>
	);
}
